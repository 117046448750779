import React from 'react';
import MapView from './components/MapView';
import './Maps.css'



function Maps() {
  return (
    <div className="Map">
      <MapView>
      </MapView>
  </div>
  );
}

export default Maps;
